<template>
  <div class="page-content">
    <page-breadcrumb title="Vocab" />
    <section class="py-3">
      <b-row>
        <b-col md="10">
          <h2 class="mb-2">Vocab Topic Management</h2>
        </b-col>
        <b-col md="2 text-right">
          <topic-create-form />
        </b-col>
      </b-row>
    </section>

    <b-row class="match-height">
      <b-col
        class="col-lg-3 col-md-6 col-12"
        v-for="(item, index) in vocab_topics"
        :key="index"
      >
        <topic-card :index="index + 1" :topic="item" />
      </b-col>
    </b-row>

    <b-pagination
      class="mt-auto"
      pills
      size="lg"
      align="center"
      v-model="current_page"
      :total-rows="total_items"
      :per-page="items_perpage"
    />
  </div>
</template>

<script>
import TopicCreateForm from "./_components/TopicCreateForm.vue";
import TopicCard from "./_components/TopicCard.vue";
import service from "../service";

export default {
  components: {
    TopicCreateForm,
    TopicCard,
  },
  data() {
    return {
      vocab_topics: null,
      current_page: 1,
      total_items: 0,
      items_perpage: 90,
    };
  },
  watch: {
    current_page() {
      this.getList();
    },
  },
  created() {
    this.getList();
  },
  methods: {
    async getList() {
      let response = await service.getList({
        page: this.current_page,
        limit: this.items_perpage,
        query: null,
        order_by: { display_order: 1 },
      });
      if (response.data.type === "DATA") {
        this.vocab_topics = response.data.data.list;
        this.total_items = response.data.data.total;
      }
    },
  },
};
</script>
