<template>
  <b-card class="text-center">
    <b-link
      :to="{ name: 'vocab-topic-detail', params: { topic_id: topic._id } }"
    >
      <b-img
        class="w-100"
        :src="topic.thumbnail.value"
        v-if="topic.thumbnail.value"
      />
      <b-img
        v-else
        :blank="true"
        blank-color="#ccc"
        alt="placeholder"
        height="172"
        class="w-100"
      />
    </b-link>
    <template #footer>
      <b-link
        :to="{ name: 'vocab-topic-detail', params: { topic_id: topic._id } }"
      >
        <h2 class="text-truncate-2 h1 mb-2">
          {{ topic.title || topic._id }}
        </h2>
        <small class="d-block mt-2" style="color: #bbb">
          Last edited: {{ topic.updatedAt | local_time_string }}
        </small>
      </b-link>
    </template>
  </b-card>
</template>

<script>
export default {
  props: {
    topic: { type: Object, required: true },
    index: { type: Number, default: 1 },
  },
};
</script>
<style scoped>
.card-footer {
  border-top: 0;
}
.card-body {
  padding-bottom: 0 !important;
}
</style>
