import api from '@/libs/axios'

export default {
  async getList(params) {
    let response = await api.get('/admin/vocab-topics', {
      params,
    })
    return response
  },
  async create(payload) {
    const response = await api.post('/admin/vocab-topics', payload)
    return response.data.data.new_document || null
  },
  async get(params) {
    let response = await api.get('/admin/vocab-topics/detail', {
      params,
    })
    return response.data.data.document || null
  },
  async update(payload) {
    let response = await api.put('/admin/vocab-topics', payload)
    return response
  },
  async delete(payload) {
    const response = await api.delete('/admin/vocab-topics', {
      data: payload,
    })
    return response
  },

  async createWord(payload) {
    const response = await api.post('/admin/vocab-words', payload)
    return response
  },
  async updateWord(payload) {
    let response = await api.put('/admin/vocab-words', payload)
    return response
  },
  async deleteWord(payload) {
    const response = await api.delete('/admin/vocab-words', {
      data: payload,
    })
    return response
  },
  async getWordList(params) {
    const response = await api.get('/admin/vocab-words', { params })
    return response
  },
}
